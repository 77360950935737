.formulario{

    legend{
       font-size: 2rem;
       color: $grisOscuro;
   }

   label{
       font-weight: $bold;
       text-transform: uppercase;
       display: block;  
   }

   input:not([type="submit"]),
   select{
       padding: 1rem;
       display: block;
       width: 100%;
       background-color: $blanco;
       border: 1px solid $gris;
       border-radius: 1rem;
       margin-bottom: 2rem;
   }
}