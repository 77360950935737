.alerta{

    padding: .5rem;
    text-align: center;
    color: #FFFFFF;
    font-weight: bold;
    text-transform: uppercase;
    margin: 1rem 0;
    &.error{
        background-color: red;
    }
}