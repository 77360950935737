.dark-mode{
    background-color: darken($grisOscuro,10);

    p{
        color: $blanco;
    }

    a,h1,h2,h3,h4{
        color: $gris;
    }

    .formulario{

        label,legend,p{
            color: $gris;
        }

        input:not([type="submit"]),
        select{
            background-color: $grisOscuro;
            border: 1px solid $grisOscuro;
            color: $blanco;
        }
    }
}