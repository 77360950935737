@include tablet{
    .iconos-nosotros{
        @include grid(3,2rem)
    }
}

.iconos{
    text-align: center;

    img{
        height: 10rem;
    }

    h3{
        text-transform: uppercase;
    }

    p{
        text-align: justify;
        // text-justify: auto;
        // text-transform: uppercase;
    }
}